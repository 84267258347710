import { makeFormURLRequest, makeRequest } from "../request"
import qs from "qs"

interface ILogin {
  email: string
  password: string
}

interface IRegister {
  email: string
  password: string
  confirm_password: string
}

let token = ""
if (typeof window !== "undefined") {
  token = window.sessionStorage.getItem("hacker_you") || ""
}
class AuthController {
  users: any | []
  invites: any
  constructor() { }
  login = (data: ILogin) => {
    return makeRequest("post", "api/auth/login", {
      email: data.email,
      password: data.password,
    })
  }

  register = async (data: IRegister) => {
    let requestData = qs.stringify({
      email: data.email,
      password: data.password,
      confirm_password: data.confirm_password,
    })

    return await makeRequest("post", "/api/auth/register", requestData)
  }

  profile = async () => {
    return await makeRequest(
      "get",
      "/api/auth/profile",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    )
      .then((response) => {
        return response.data
      })
  }
}

export default new AuthController()
