import { adminUserKeys } from "medusa-react"
import React, { useEffect, useReducer, useState } from "react"
import Medusa from "../services/auth"
import { queryClient } from "../services/config"
import { Link, navigate } from "gatsby"

import AuthController from "../services/auth"
import { toast } from "react-hot-toast"
import { useLocation } from "@reach/router"
import StoreController from "../services/store"

export const AccountContext = React.createContext({
  render: false,
  getStore: async () => { },
  store: null,
  profile: null
})

export const AccountProvider = ({ children }) => {
  const [profile, setProfile] = useState(null)
  const [render, setRender] = useState(false);
  const [store, setStore] = useState(null);

  const location = useLocation()

  const getStore = async () => {
    const storeId = localStorage.getItem('current_store_id')
    if (!storeId)
      return

    setRender(false)
    await StoreController.getStoreDetail(storeId)
      .then(e => {
        setStore(e?.result?.store)
      })
      .finally(() => {
        setRender(true)
      })
  }

  const getProfile = async () => {
    AuthController.profile()
      .then((res) => {
        setProfile(res)
        setTimeout(() => {
          setRender(true)
        }, 1000)
      })
      .catch(() => {
        window?.sessionStorage.removeItem('hacker_you')
        console.log('adsfasdf')
        setProfile(null)
        navigate("/login") // check fail api get profile and redirect to /login
      })
  }

  useEffect(() => {
    try {
      const token = window?.sessionStorage.getItem("hacker_you")
      if (token && !profile) {
        getProfile()
      }
      else if (token && profile) {
        setRender(true)
      }
      else if (location.pathname.includes('/login'))
        return setRender(true);
      else {
        setTimeout(() => {
          navigate('/login')
        }, 3000)
      }
    }
    finally {
    }
  }, [])

  return (
    <AccountContext.Provider
      value={{
        render,
        getStore,
        store,
        profile
      }}
    // value={{
    //   login,
    // }}
    // value={{
    //   ...state,
    //   session: () => {
    //     return Medusa.auth.session().then(({ data }) => {
    //       dispatch({ type: "userAuthenticated", payload: data.user })
    //       return data
    //     })
    //   },

    //   handleUpdateUser: (id, user) => {
    //     return Medusa.users.update(id, user).then(({ data }) => {
    //       queryClient.invalidateQueries(adminUserKeys.all)
    //       dispatch({ type: "updateUser", payload: data.user })
    //     })
    //   },

    //   handleLogout: (details) => {
    //     return Medusa.auth.deauthenticate(details).then(() => {
    //       dispatch({ type: "userLoggedOut" })
    //       return null
    //     })
    //   },

    //   handleLogin: (details) => {
    //     return Medusa.auth.authenticate(details).then(({ data }) => {
    //       dispatch({ type: "userLoggedIn", payload: data.user })
    //       return data
    //     })
    //   },
    // }}
    >
      {children}

    </AccountContext.Provider>
  )
}
