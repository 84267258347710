exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-a-jsx": () => import("./../../../src/pages/a.jsx" /* webpackChunkName: "component---src-pages-a-jsx" */),
  "component---src-pages-creator-finance-tsx": () => import("./../../../src/pages/creator/finance.tsx" /* webpackChunkName: "component---src-pages-creator-finance-tsx" */),
  "component---src-pages-creator-insight-tsx": () => import("./../../../src/pages/creator/insight.tsx" /* webpackChunkName: "component---src-pages-creator-insight-tsx" */),
  "component---src-pages-creator-notifications-index-tsx": () => import("./../../../src/pages/creator/notifications/index.tsx" /* webpackChunkName: "component---src-pages-creator-notifications-index-tsx" */),
  "component---src-pages-creator-payout-tsx": () => import("./../../../src/pages/creator/payout.tsx" /* webpackChunkName: "component---src-pages-creator-payout-tsx" */),
  "component---src-pages-creator-settings-index-tsx": () => import("./../../../src/pages/creator/settings/index.tsx" /* webpackChunkName: "component---src-pages-creator-settings-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invite-tsx": () => import("./../../../src/pages/invite.tsx" /* webpackChunkName: "component---src-pages-invite-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-nail-appointments-tsx": () => import("./../../../src/pages/nail/appointments.tsx" /* webpackChunkName: "component---src-pages-nail-appointments-tsx" */),
  "component---src-pages-nail-clients-tsx": () => import("./../../../src/pages/nail/clients.tsx" /* webpackChunkName: "component---src-pages-nail-clients-tsx" */),
  "component---src-pages-nail-dashboard-tsx": () => import("./../../../src/pages/nail/dashboard.tsx" /* webpackChunkName: "component---src-pages-nail-dashboard-tsx" */),
  "component---src-pages-nail-payments-tsx": () => import("./../../../src/pages/nail/payments.tsx" /* webpackChunkName: "component---src-pages-nail-payments-tsx" */),
  "component---src-pages-nail-services-tsx": () => import("./../../../src/pages/nail/services.tsx" /* webpackChunkName: "component---src-pages-nail-services-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-retail-customers-details-tsx": () => import("./../../../src/pages/retail/customers/details.tsx" /* webpackChunkName: "component---src-pages-retail-customers-details-tsx" */),
  "component---src-pages-retail-customers-groups-tsx": () => import("./../../../src/pages/retail/customers/groups.tsx" /* webpackChunkName: "component---src-pages-retail-customers-groups-tsx" */),
  "component---src-pages-retail-customers-index-tsx": () => import("./../../../src/pages/retail/customers/index.tsx" /* webpackChunkName: "component---src-pages-retail-customers-index-tsx" */),
  "component---src-pages-retail-dashboard-tsx": () => import("./../../../src/pages/retail/dashboard.tsx" /* webpackChunkName: "component---src-pages-retail-dashboard-tsx" */),
  "component---src-pages-retail-discounts-tsx": () => import("./../../../src/pages/retail/discounts.tsx" /* webpackChunkName: "component---src-pages-retail-discounts-tsx" */),
  "component---src-pages-retail-draft-orders-details-tsx": () => import("./../../../src/pages/retail/draft-orders/details.tsx" /* webpackChunkName: "component---src-pages-retail-draft-orders-details-tsx" */),
  "component---src-pages-retail-draft-orders-index-tsx": () => import("./../../../src/pages/retail/draft-orders/index.tsx" /* webpackChunkName: "component---src-pages-retail-draft-orders-index-tsx" */),
  "component---src-pages-retail-gift-cards-index-tsx": () => import("./../../../src/pages/retail/gift-cards/index.tsx" /* webpackChunkName: "component---src-pages-retail-gift-cards-index-tsx" */),
  "component---src-pages-retail-gift-cards-manage-tsx": () => import("./../../../src/pages/retail/gift-cards/manage.tsx" /* webpackChunkName: "component---src-pages-retail-gift-cards-manage-tsx" */),
  "component---src-pages-retail-insights-index-tsx": () => import("./../../../src/pages/retail/insights/index.tsx" /* webpackChunkName: "component---src-pages-retail-insights-index-tsx" */),
  "component---src-pages-retail-orders-details-tsx": () => import("./../../../src/pages/retail/orders/details.tsx" /* webpackChunkName: "component---src-pages-retail-orders-details-tsx" */),
  "component---src-pages-retail-orders-index-tsx": () => import("./../../../src/pages/retail/orders/index.tsx" /* webpackChunkName: "component---src-pages-retail-orders-index-tsx" */),
  "component---src-pages-retail-pricing-index-tsx": () => import("./../../../src/pages/retail/pricing/index.tsx" /* webpackChunkName: "component---src-pages-retail-pricing-index-tsx" */),
  "component---src-pages-retail-pricing-new-tsx": () => import("./../../../src/pages/retail/pricing/new.tsx" /* webpackChunkName: "component---src-pages-retail-pricing-new-tsx" */),
  "component---src-pages-retail-products-[id]-tsx": () => import("./../../../src/pages/retail/products/[id].tsx" /* webpackChunkName: "component---src-pages-retail-products-[id]-tsx" */),
  "component---src-pages-retail-products-index-tsx": () => import("./../../../src/pages/retail/products/index.tsx" /* webpackChunkName: "component---src-pages-retail-products-index-tsx" */),
  "component---src-pages-retail-settings-currencies-tsx": () => import("./../../../src/pages/retail/settings/currencies.tsx" /* webpackChunkName: "component---src-pages-retail-settings-currencies-tsx" */),
  "component---src-pages-retail-settings-details-tsx": () => import("./../../../src/pages/retail/settings/details.tsx" /* webpackChunkName: "component---src-pages-retail-settings-details-tsx" */),
  "component---src-pages-retail-settings-index-tsx": () => import("./../../../src/pages/retail/settings/index.tsx" /* webpackChunkName: "component---src-pages-retail-settings-index-tsx" */),
  "component---src-pages-retail-settings-payments-tsx": () => import("./../../../src/pages/retail/settings/payments.tsx" /* webpackChunkName: "component---src-pages-retail-settings-payments-tsx" */),
  "component---src-pages-retail-settings-personal-information-tsx": () => import("./../../../src/pages/retail/settings/personal-information.tsx" /* webpackChunkName: "component---src-pages-retail-settings-personal-information-tsx" */),
  "component---src-pages-retail-settings-regions-index-tsx": () => import("./../../../src/pages/retail/settings/regions/index.tsx" /* webpackChunkName: "component---src-pages-retail-settings-regions-index-tsx" */),
  "component---src-pages-retail-settings-regions-new-tsx": () => import("./../../../src/pages/retail/settings/regions/new.tsx" /* webpackChunkName: "component---src-pages-retail-settings-regions-new-tsx" */),
  "component---src-pages-retail-settings-return-reasons-tsx": () => import("./../../../src/pages/retail/settings/return-reasons.tsx" /* webpackChunkName: "component---src-pages-retail-settings-return-reasons-tsx" */),
  "component---src-pages-retail-settings-sales-channels-tsx": () => import("./../../../src/pages/retail/settings/sales-channels.tsx" /* webpackChunkName: "component---src-pages-retail-settings-sales-channels-tsx" */),
  "component---src-pages-retail-settings-shipping-profiles-tsx": () => import("./../../../src/pages/retail/settings/shipping-profiles.tsx" /* webpackChunkName: "component---src-pages-retail-settings-shipping-profiles-tsx" */),
  "component---src-pages-retail-settings-taxes-tsx": () => import("./../../../src/pages/retail/settings/taxes.tsx" /* webpackChunkName: "component---src-pages-retail-settings-taxes-tsx" */),
  "component---src-pages-retail-settings-team-tsx": () => import("./../../../src/pages/retail/settings/team.tsx" /* webpackChunkName: "component---src-pages-retail-settings-team-tsx" */),
  "component---src-pages-stores-appointments-tsx": () => import("./../../../src/pages/stores/appointments.tsx" /* webpackChunkName: "component---src-pages-stores-appointments-tsx" */),
  "component---src-pages-stores-clients-tsx": () => import("./../../../src/pages/stores/clients.tsx" /* webpackChunkName: "component---src-pages-stores-clients-tsx" */),
  "component---src-pages-stores-dashboard-tsx": () => import("./../../../src/pages/stores/dashboard.tsx" /* webpackChunkName: "component---src-pages-stores-dashboard-tsx" */),
  "component---src-pages-stores-finance-tsx": () => import("./../../../src/pages/stores/finance.tsx" /* webpackChunkName: "component---src-pages-stores-finance-tsx" */),
  "component---src-pages-stores-payments-tsx": () => import("./../../../src/pages/stores/payments.tsx" /* webpackChunkName: "component---src-pages-stores-payments-tsx" */),
  "component---src-pages-stores-services-tsx": () => import("./../../../src/pages/stores/services.tsx" /* webpackChunkName: "component---src-pages-stores-services-tsx" */)
}

