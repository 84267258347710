import { makeFormURLRequest, makeRequest } from "../request"
import qs from "qs"
import StoreServiceController from "./service"
import StoreCategoryController from "./category"

interface ILogin {
  email: string
  password: string
}

interface IRegister {
  email: string
  password: string
  confirm_password: string
}

let token = ""
if (typeof window !== "undefined") {
  token = window.sessionStorage.getItem("hacker_you") || ""
}
class StoreController {

  getMcc = async () => {
    return await makeRequest(
      "get",
      "/api/mccs",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
  }

  createStore = async ({ storeName, mccId, regionId }) => {
    return await makeRequest(
      "post",
      "/api/stores",
      {
        mccId,
        regionId,
        name: storeName,

      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getStores = async () => {
    return await makeRequest(
      "get",
      "/api/stores",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getRegions = async () => {
    return await makeRequest(
      "get",
      "/api/regions",
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response?.data?.result
      })
  }

  createAccount = async (
    storeId: string,
    routingNumber: string,
    accountNumber: string,
    returnUrl: string,
    refreshUrl: string) => {
    return await makeRequest(
      "post",
      "/api/stores/account/create",
      {
        storeId,
        routingNumber,
        accountNumber,
        returnUrl,
        refreshUrl
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getStoreDetail = async (storeId: string) => {
    return await makeRequest(
      "get",
      `/api/stores/detail/${storeId}`,
      {},
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  genUpdateLink = async (storeId: string, returnUrl: string, refreshUrl: string) => {
    return await makeRequest(
      "post",
      "/api/stores/account/gen-update",
      {
        storeId,
        returnUrl,
        refreshUrl
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  activePayment = async (storeId: string, paymentId: string) => {
    return await makeRequest(
      "post",
      "/api/stores/payment/active",
      {
        storeId,
        paymentId,
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  getCategories = async (storeId: string) => {
    return await makeRequest(
      "get",
      `/api/categories/${storeId}/list`,
      {
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  addCategory = async (name: string, description?: string, color?: string, storeId?: string) => {
    return await makeRequest(
      "post",
      `/api/categories`,
      {
        name,
        description,
        color,
        storeId
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

  updateProductCategory = async (categoryId: string, storeId: string, productId: string) => {
    return await makeRequest(
      "post",
      `/api/products/${productId}/category`,
      {
        storeId,
        categoryId
      },
      {
        authorization: `Bearer ${token}`,
      }
    )
      .catch((error) => {
        return error
      })
      .then((response) => {
        return response.data
      })
  }

}

export default new StoreController()
