import { MedusaProvider } from "medusa-react"
import React from "react"
import "./src/assets/styles/emoji-picker.css"
import "./src/assets/styles/global.css"
import "./src/assets/styles/custom.css"
import { WRITE_KEY } from "./src/components/constants/analytics"
import { LayeredModalProvider } from "./src/components/molecules/modal/layered-modal"
import { SteppedProvider } from "./src/components/molecules/modal/stepped-modal"
import ErrorBoundary from "./src/components/organisms/error-boundary"
import AnalyticsProvider from "./src/context/analytics"
import { CacheProvider } from "./src/context/cache"
import { FeatureFlagProvider } from "./src/context/feature-flag"
import { AccountProvider } from "./src/context/account"
import { P } from "./src/context/interface"
import { medusaUrl, queryClient } from "./src/services/config"
import { InterfaceProvider } from "./src/context/interface"
import { ThemeProvider } from "@material-tailwind/react";
import { Toaster } from "react-hot-toast"

export const wrapPageElement = ({ element }) => {
  return (
    <MedusaProvider
      baseUrl={null}
      queryClientProviderProps={{
        client: queryClient,
      }}
    >
      <Toaster
        containerStyle={{
          top: 74,
          left: 24,
          bottom: 24,
          right: 24,
          zIndex: 99999999
        }}
      />
      {/* <ThemeProvider> */}
      <CacheProvider>
        <AccountProvider>
          <InterfaceProvider>
            <LayeredModalProvider>
              <ErrorBoundary>{element}</ErrorBoundary>
            </LayeredModalProvider>
          </InterfaceProvider>
        </AccountProvider>
      </CacheProvider>
      {/* </ThemeProvider> */}
    </MedusaProvider>
  )
}
