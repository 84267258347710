import axios from "axios"

let baseURL = "https://dev-ecom.myepis.cloud"
// let baseURL = "http://localhost:3333"

const client = axios.create({ baseURL })

export const makeRequest = (method, path = "", payload = {}, headers = {}) => {
  const options = {
    method,
    // withCredentials: true,
    url: path,
    data: payload,
    json: true,
    headers: headers,
  }
  return client(options)
}

export const makeFormURLRequest = async (path, payload) => {
  const options = {
    // withCredentials: true,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  }
  const result = await client.post(path, payload, options).catch((e) => e)
  if (result instanceof Error) return result.response

  return result
}

export const makeFormRequest = async (path, payload, headers) => {
  const options = {
    // withCredentials: true,
    headers: { ...payload.getHeaders(), ...headers },
  }
  const result = await client.post(path, payload, options).catch((e) => e)
  if (result instanceof Error) return result.response

  return result
}
